import * as React from 'react';

const SvgIcon = ({ ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ?? '24'}
        height={props.size ?? '24'}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path fill="#fff" d="M12 17 4 8h16z"></path>
    </svg>
);

export default SvgIcon;
