export function sliceLastId(Id: string, len: number = 5): string {
    if (Id) {
        return (Id + '').slice(Id.length - len, Id.length);
    } else {
        return '';
    }
}

export const generateImageUrl = (
    eggOrHero: 'Egg' | 'Hero',
    _nftId: string,
    _type: string,
    _rarity: string
) => {
    if (!_nftId || !_type || !_rarity) return '';
    const domain = 'https://cdn.tinyhero.io';
    const fileName = eggOrHero === 'Egg' ? (_nftId + '').substr(2, 2) : _nftId;
    const type =
        _type.toLocaleLowerCase() === 'Intelligence'.toLocaleLowerCase()
            ? 'intelligent'
            : _type?.toLowerCase();
    return `${domain}/${
        eggOrHero === 'Egg' ? 'egg' : 'nft'
    }/${_rarity?.toLowerCase()}/${type}/${fileName}.png`;
};

export const formatNumberToString = (num: number) => {
    if (!num) return 0;

    return num
        .toString()
        .split(/(?=(?:\d{3})+(?:\.|$))/g)
        .join(',');
};

export function getColorByAttribute(attribute: string): string {
    switch (attribute) {
        case 'Water':
            return '#00BAFF';
        case 'Fire':
            return ' #FF561E';
        case 'Grass':
            return '#4DDF23';
        case 'Electric':
            return '#FFD600';
        case 'Light':
            return '#EAB232';
        case 'Dark':
            return '#B829FF';
        case 'Neutral':
            return '#C4C4C4';
        default:
            return '#00BAFF';
    }
}

export function perDollarEarn(
    tinyHolder: number,
    day: number,
    tradingVolume: number,
    supplyX: number,
    percent: number = 0.04
): number {
    if (supplyX > 0) {
        return ((tradingVolume * percent * tinyHolder) / supplyX) * day;
    } else {
        return 0;
    }
}

export const changeObjectToQueryString = (object: any) => {
    let url = '';

    for (const key in object) {
        if (
            object.hasOwnProperty(key) &&
            object[key] !== '' &&
            object[key] !== undefined &&
            object[key] !== null
        ) {
            url += `&${key}=${object[key]}`;
        }
    }

    return '?' + url.slice(1);
};

export const getSuperHeroClassName = (type: number) => {
    switch (type) {
        case 0:
            return 'Common';
        case 1:
            return 'Uncommon';
        case 2:
            return 'Rare';
        case 3:
            return 'Epic';
        case 4:
            return 'Legendary';
        case 5:
            return 'Mythic';

        default:
            return 'Common';
    }
};

export const getMaxPointBySuperHeroLevel = (level: number) => {
    switch (level) {
        case 1:
            return 99;
        case 2:
            return 299;
        case 3:
            return 599;
        case 4:
            return 999;
        case 5:
            return 1599;
        case 6:
            return 2199;
        case 7:
            return 2999;
        case 8:
            return 3899;
        case 9:
            return 4999;
        case 10:
            return 1000000;
        default:
            return 99;
    }
};

export const getIndexNFT = (productivity: number) => {
    if (productivity >= 11) return 1;

    if (productivity >= 6) return 2;

    if (productivity >= 1) return 3;

    return 0;
};

export function maxBetPerLevelBalance(bal: any) {
    let prize;

    if (bal >= 10000 * 1e18) prize = 500 * 1e18;
    else if (bal >= 5000 * 1e18) prize = 200 * 1e18;
    else if (bal >= 2000 * 1e18) prize = 100 * 1e18;
    else if (bal >= 1000 * 1e18) prize = 50 * 1e18;
    else if (bal >= 500 * 1e18) prize = 20 * 1e18;
    else if (bal >= 200 * 1e18) prize = 10 * 1e18;
    else prize = 5 * 1e18;

    return prize;
}
