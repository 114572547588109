const SvgIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="28"
        fill="none"
        viewBox="0 0 100 28"
    >
        <g filter="url(#filter0_i_2639_766)">
            <path
                fill="url(#paint0_linear_2639_766)"
                d="M.948 8.852C.442 4.124 4.148 0 8.903 0h82.194c4.756 0 8.461 4.124 7.955 8.852l-1.286 12A8 8 0 0 1 89.81 28H10.19a8 8 0 0 1-7.955-7.148z"
            ></path>
        </g>
        <path
            stroke="#CEFFEF"
            strokeWidth="2"
            d="M1.943 8.746C1.499 4.608 4.742 1 8.903 1h82.194c4.161 0 7.404 3.608 6.96 7.746l-1.285 12A7 7 0 0 1 89.812 27H10.188a7 7 0 0 1-6.96-6.254z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_2639_766"
                x1="50"
                x2="50"
                y1="0"
                y2="28"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#DAF8E9"></stop>
                <stop offset="1" stopColor="#A1D9E7"></stop>
            </linearGradient>
            <filter
                id="filter0_i_2639_766"
                width="98.195"
                height="28"
                x="0.902"
                y="0"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feColorMatrix
                    in="SourceAlpha"
                    result="hardAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                ></feColorMatrix>
                <feOffset dy="-10"></feOffset>
                <feComposite
                    in2="hardAlpha"
                    k2="-1"
                    k3="1"
                    operator="arithmetic"
                ></feComposite>
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                <feBlend
                    in2="shape"
                    result="effect1_innerShadow_2639_766"
                ></feBlend>
            </filter>
        </defs>
    </svg>
);

export default SvgIcon;
