const SvgIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        fill="none"
        viewBox="0 0 25 24"
    >
        <path
            fill="#95FF00"
            d="M11.585 2C7.127 2 3.5 5.627 3.5 10.085v8.936A2.98 2.98 0 0 0 6.479 22h10.213a2.98 2.98 0 0 0 2.978-2.979v-2.188a1.7 1.7 0 0 0 1.04-.79h.237a2.13 2.13 0 0 0 2.128-2.128v-1.608c.259-.234.425-.569.425-.945a1.277 1.277 0 1 0-2.553 0c0 .376.166.711.425.945v1.608c0 .234-.19.425-.425.425h-.236a1.7 1.7 0 0 0-1.04-.79v-3.465C19.67 5.627 16.042 2 11.584 2M7.33 10.085h1.702c.235 0 .425.19.425.426v2.978c0 .235-.19.426-.425.426H7.33a.426.426 0 0 1-.426-.426v-2.978c0-.235.19-.426.426-.426m3.404 0h1.702c.235 0 .426.19.426.426v2.978c0 .235-.19.426-.426.426h-1.702a.426.426 0 0 1-.425-.426v-2.978c0-.235.19-.426.425-.426m3.404 0h1.702c.235 0 .426.19.426.426v2.978c0 .235-.19.426-.426.426h-1.702a.426.426 0 0 1-.425-.426v-2.978c0-.235.19-.426.425-.426m-5.532 5.958h5.958a.851.851 0 0 1 0 1.702H8.606a.851.851 0 0 1 0-1.702"
        ></path>
    </svg>
);

export default SvgIcon;
