import { useCallback, useEffect, useState } from 'react';
import { provider } from 'web3-core';

// import { getBalance } from '../utils/erc20';
import useBlock from './useBlock';
import { getBalance } from '@wagmi/core';
import { useAccount } from 'wagmi';
import { config } from 'utils/wallet/wagmi';
import { formatUnits } from 'viem';

const useTokenBalance = (tokenAddress: string) => {
    const [balance, setBalance] = useState(0);
    const { address } = useAccount();
    // const block = useBlock();

    // const fetchBalance = useCallback(async () => {
    //     if (account) {
    //         const balance = await getBalance(ethereum, tokenAddress, account);
    //         setBalance(balance);
    //     }
    // }, [account, ethereum, tokenAddress]);

    // useEffect(() => {
    //     if (account && ethereum && tokenAddress) {
    //         fetchBalance();
    //     }
    // }, [account, ethereum, setBalance, block, tokenAddress, fetchBalance]);

    // return balance;
    const handleGetBalance = async () => {
        if (address) {
            const result = await getBalance(config, {
                address,
                token: tokenAddress as Address,
            });

            const balance = formatUnits(result.value, result.decimals);
            setBalance(Number(balance));
        } else {
            setBalance(0);
        }
    };

    useEffect(() => {
        handleGetBalance();
    }, []);

    return { balance, handleGetBalance };
};

export default useTokenBalance;
