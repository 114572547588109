import { Config } from './type';

export const configs: Record<string, Config> = {
    development: {
        chainId: 64165,
        contractAddresses: {
            TokenPay: '0xb58B168d65e3dBd5aE6A62A64cfc6389D146a82c',
            FrogsPoint: '0xe3b8a3dcc57508f0f53dc3527e7e72bd40e47774',
            TinyNFT: '0xF4475B5d780d511C59962c62Dd08576e01e4b081',
            EggNFT: '0xA722AD7590a9A72E780faB7F74ca7358F34BDEe1',
            UpDown: '0x1f07293782e53328eae42151ee77e723d419bed6',
            UpDownRef: '0x6f148acc4b9f978c10dc31d9f5adb7f25565a148',
            UpDownNFT: '0x523196D752B2EE657462Bf9d26AA887D819F4490',
            TailToken: '0xb58B168d65e3dBd5aE6A62A64cfc6389D146a82c',
            TailNFT: '0xF4475B5d780d511C59962c62Dd08576e01e4b081',
        },
        TINY: '0xb58B168d65e3dBd5aE6A62A64cfc6389D146a82c',
        MATIC: '0x0000000000000000000000000000000000001010',
        PAY: 'TINY',
        explorer: 'https://scan.soniclabs.com/',
        explorerName: 'PolygonScan',
        defaultProvider: 'https://p-rpc-eu.testnet.soniclabs.com',
        apiUrl: 'https://stg-api.frogs.city',
        fusionFee: 1,
        defusionFee: 5,
        subgraphBattle:
            'https://api.thegraph.com/subgraphs/name/tinyhero-io/history-game',
    },
    production: {
        chainId: 64165,
        contractAddresses: {
            TokenPay: '0xb58B168d65e3dBd5aE6A62A64cfc6389D146a82c',
            FrogsPoint: '0xe3b8a3dcc57508f0f53dc3527e7e72bd40e47774',
            TinyNFT: '0xF4475B5d780d511C59962c62Dd08576e01e4b081',
            EggNFT: '0xA722AD7590a9A72E780faB7F74ca7358F34BDEe1',
            UpDown: '0x1f07293782e53328eae42151ee77e723d419bed6',
            UpDownRef: '0x6f148acc4b9f978c10dc31d9f5adb7f25565a148',
            UpDownNFT: '0x523196D752B2EE657462Bf9d26AA887D819F4490',
            TailToken: '0xb58B168d65e3dBd5aE6A62A64cfc6389D146a82c',
            TailNFT: '0xF4475B5d780d511C59962c62Dd08576e01e4b081',
        },
        TINY: '0xb58B168d65e3dBd5aE6A62A64cfc6389D146a82c',
        MATIC: '0x0000000000000000000000000000000000001010',
        PAY: 'TINY',
        explorer: 'https://scan.soniclabs.com/',
        explorerName: 'PolygonScan',
        defaultProvider: 'https://p-rpc-eu.testnet.soniclabs.com',
        apiUrl: 'https://api.frogs.city',
        fusionFee: 5000,
        defusionFee: 10000,
        subgraphBattle:
            'https://api.thegraph.com/subgraphs/name/tinyhero-io/history-game-prod',
    },
};

export const currentConfig =
    configs[process.env.REACT_APP_ENV || 'development'] || {};
