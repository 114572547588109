import { currentConfig } from 'common/configs';
import useTokenBalance from 'hooks/useTokenBalance';
import { createContext, useContext, useEffect } from 'react';
import { useAccount } from 'wagmi';

const BalanceContext = createContext<{
    balance: number;
    refetch: () => Promise<void>;
}>({
    balance: 0,
    refetch: async () => {},
});

interface Props {
    children: React.ReactNode;
}

const BalanceProvider: React.FC<Props> = ({ children }) => {
    const { balance, handleGetBalance } = useTokenBalance(
        currentConfig.contractAddresses.FrogsPoint
    );
    const account = useAccount();

    const refetch = async () => {
        await handleGetBalance();
    };

    useEffect(() => {
        refetch();
        const intervalId = setInterval(() => {
            refetch();
        }, 10000);

        return () => {
            clearInterval(intervalId);
        };
    }, [account]);

    return (
        <BalanceContext.Provider
            value={{
                balance,
                refetch,
            }}
        >
            {children}
        </BalanceContext.Provider>
    );
};

export const useBalanceContext = () => {
    const value = useContext(BalanceContext);

    if (!value) {
        throw new Error('Balance must be wrap inside BalanceProvider');
    }

    return value;
};

export default BalanceProvider;
