const SvgIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 14 14"
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity="0.7"
            strokeWidth="2"
            d="M1 12.633 13 1M1 1l12 11.633"
        ></path>
    </svg>
);

export default SvgIcon;
