import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import '@rainbow-me/rainbowkit/styles.css';

import { Buffer } from 'buffer';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { config } from 'utils/wallet/wagmi';
import BalanceProvider from 'contexts/BalanceContext';
import './style.scss';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

window.Buffer = Buffer;

const queryClient = new QueryClient();
const apolloClient = new ApolloClient({
    uri: 'https://graph.frogsclub.xyz/query/subgraphs/name/frogs-updown-subgraph',
    cache: new InMemoryCache(),
});

ReactDOM.render(
    <React.StrictMode>
        <WagmiProvider config={config}>
            <ApolloProvider client={apolloClient}>
                <QueryClientProvider client={queryClient}>
                    <RainbowKitProvider
                        modalSize="compact"
                        theme={darkTheme({
                            accentColor: '#ff9f0a',
                        })}
                    >
                        <BalanceProvider>
                            <App />
                        </BalanceProvider>
                    </RainbowKitProvider>
                </QueryClientProvider>
            </ApolloProvider>
        </WagmiProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
