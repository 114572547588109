import { useContext } from 'react';

import { Context } from '../contexts/TinyProvider';

const useTiny = () => {
    const { tiny } = useContext(Context);

    return tiny;
};

export default useTiny;
