export const WIN = "win";
export const LOSE = "lose";
export const INVALID_LIST_INPUT = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  ".",
  "Backspace",
  "Delete",
  "ArrowLeft",
  "ArrowRight",
];

export const DOMAIN = window.location.origin;
