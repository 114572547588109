const SvgIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9789 0L22 5.98635V17.9962L11.9905 24L11.9095 23.9793L2 17.9962V5.98635L11.9095 0H11.9789ZM11.9463 1.24247L3.05263 6.61414V17.3684L11.9453 22.739L13.0316 22.0872L20.9474 17.3651V6.61632L13.0316 1.86698L11.9463 1.24247ZM4.43053 12.8265L7.06316 10.7848L9.39053 12.3264V15.0996L11.1516 16.8596L11.1505 17.685L9.45368 19.3336H8.02105L4.43158 12.8265H4.43053ZM12.7495 17.6872L12.7463 16.8574L14.5011 15.0996V12.3264L16.8032 10.7652L19.4305 12.8276L15.8589 19.3151H14.4442L12.7495 17.6872ZM10.2547 12.3264L9.39789 9.99982H14.4905L13.6505 12.3264L13.8989 14.9271L11.9337 14.9315L9.99158 14.9348L10.2547 12.3264ZM11.9337 9.36221L7.09368 9.36002L7.99368 5.18934H15.8505L16.7979 9.36548L11.9337 9.36221Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default SvgIcon;
