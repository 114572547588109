/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import useTiny from 'hooks/useTiny';
import { useAccount } from 'wagmi';
import { multicall, readContract } from '@wagmi/core';
import { config } from 'utils/wallet/wagmi';
import _ from 'lodash';
import { RARITY } from 'utils';

const useGetMoreNFT = (lockNFT: string) => {
    const { address: account } = useAccount();

    const tiny = useTiny();

    const [userNFT, setUserNFT] = useState<any>(null);

    const fetchNewNFT = useCallback(async () => {
        try {
            if (!tiny) return;
            const req = await readContract(config, {
                abi: tiny?.contracts.UpDownNFT.options.jsonInterface,
                address: tiny?.contracts.UpDownNFT.options.address as Address,
                functionName: 'locked',
                args: [account],
            }).then(async (res) => {
                const calls = [
                    {
                        address: tiny.contracts.TailNFT.options
                            .address as Address,
                        abi: tiny.contracts.TailNFT.options
                            .jsonInterface as any,
                        functionName: 'getNFTInfo',
                        args: [Number(res)],
                    },
                    {
                        address: tiny.contracts.TailNFT.options
                            .address as Address,
                        abi: tiny.contracts.TailNFT.options
                            .jsonInterface as any,
                        functionName: 'rarityOfToken',
                        args: [Number(res)],
                    },
                ];
                const data = await multicall(config, {
                    contracts: calls,
                });
                let mergedInfoNftLock;
                const nftInfo = _.mapValues((data[0].result as any)[1], (o) =>
                    Number(o)
                );
                const rarity: any = data[1].result;
                const rarityInfo = RARITY[rarity];
                mergedInfoNftLock = {
                    ...nftInfo,
                    rarity,
                    nftId: Number(res),
                    rarityInfo,
                };

                if (mergedInfoNftLock) setUserNFT(mergedInfoNftLock);
            });
        } catch (error) {
            console.log('error', error);
        }
    }, [tiny, lockNFT, account]);

    useEffect(() => {
        if (!tiny) return;

        if (lockNFT && lockNFT !== '0') {
            fetchNewNFT();
        }
    }, [tiny, lockNFT, account]);

    return userNFT;
};

export default useGetMoreNFT;
