import React, { lazy, Suspense, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { UseWalletProvider } from 'use-wallet';
import history from './routerHistory';

import 'antd/dist/antd.css';
import './assets/fonts/fonts.scss';
import './assets/scss/index.scss';

import Menu from 'views/Common/Menu/Menu';
import FooterPage from './views/Common/FooterPage/FooterPage';

import TinyProvider from './contexts/TinyProvider';
import ResultsProvider from './contexts/ResultsProvider';
import UserNFTProvider from './contexts/UserNFTProvider';
import HistoryProvider from './contexts/HistoryProvider';
import { ModalContextProvider } from 'contexts/ModalContext';
import { MarketContextProvider } from 'contexts/MarketContext';

import { LayoutTiny } from 'views/Common/LayoutTiny/LayoutTiny';
import Loading from 'views/Common/Loading';
import ScrollToTop from 'views/Common/ScrollToTop';
import { YourTinyContextProvider } from 'contexts/TinyContext';
import LayoutBackHeader from 'views/Common/HeaderBack/LayoutBackHeader';

const Updown = lazy(() => import('./views/Updown'));
const Referral = lazy(() => import('./views/Referral'));

function App() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const referral = params.get('referral');

    useEffect(() => {
        if (referral) {
            localStorage.referral = referral;
        }
    }, []);

    return (
        <Providers>
            <Suspense fallback={<Loading />}>
                <ModalContextProvider>
                    <MarketContextProvider>
                        <YourTinyContextProvider>
                            <Router history={history}>
                                <ScrollToTop />
                                <Switch>
                                    <Route path="/" exact component={Updown} />
                                    <Route
                                        path="/referral"
                                        component={Referral}
                                    />
                                </Switch>
                            </Router>
                        </YourTinyContextProvider>
                    </MarketContextProvider>
                </ModalContextProvider>
            </Suspense>
        </Providers>
    );
}

const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        // <UseWalletProvider chainId={137}>
        <TinyProvider>
            <ResultsProvider>
                <HistoryProvider>
                    <UserNFTProvider>{children}</UserNFTProvider>
                </HistoryProvider>
            </ResultsProvider>
        </TinyProvider>
        // </UseWalletProvider>
    );
};

export const Layout = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <>
                    <Menu />
                    <Component {...props} />
                    <FooterPage />
                </>
            )}
        />
    );
};
export const TinyLayoutRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <LayoutTiny>
                    <Component {...props} />
                </LayoutTiny>
            )}
        />
    );
};

export const TinyBackHeaderRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <LayoutBackHeader>
                    <Component {...props} />
                </LayoutBackHeader>
            )}
        />
    );
};

export default App;
