import { Common, Epic, Legendary, Rare, Uncommon } from 'assets';

export const RARITY = [
    {
        label: 'Common',
        img: Common,
        color: '#B5CEFF',
    },
    {
        label: 'Uncommon',
        img: Uncommon,
        color: '#52D2FF',
    },
    {
        label: 'Rare',
        img: Rare,
        color: '#B1F1AE',
    },
    {
        label: 'Epic',
        img: Epic,
        color: '#F4BD02',
    },
    {
        label: 'Legendary',
        img: Legendary,
        color: '#FFAD72',
    },
] as const;
