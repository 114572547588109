import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { Config } from './type';
import { currentConfig } from './configs';
import { abis } from './abis';

export class Contracts {
    private config: Config;
    public tokens: any = {};
    public TINY: Contract;
    public PAY: Contract;
    public EggNFT: Contract;
    public TinyNFT: Contract;
    public UpDown: Contract;
    public UpDownRef: Contract;
    public UpDownNFT: Contract;
    public TailToken: Contract;
    public TailNFT: Contract;
    public FrogsPoint: Contract;

    constructor(provider: any, public web3: Web3) {
        this.config = currentConfig;
        this.TINY = new this.web3.eth.Contract(abis.ERC20Abi);

        const payAbi =
            currentConfig.PAY === 'TDO' ? abis.TDOAbi : abis.ERC20Abi;
        this.PAY = new this.web3.eth.Contract(payAbi);

        this.EggNFT = new this.web3.eth.Contract(abis.EggNFTAbi);
        this.TinyNFT = new this.web3.eth.Contract(abis.TinyNFTAbi);
        this.UpDown = new this.web3.eth.Contract(abis.UpDownAbi);
        this.UpDownRef = new this.web3.eth.Contract(abis.UpDownRefAbi);
        this.UpDownNFT = new this.web3.eth.Contract(abis.UpDownNFTAbi);
        this.TailToken = new this.web3.eth.Contract(abis.TailTokenAbi);
        this.TailNFT = new this.web3.eth.Contract(abis.TailNFTAbi);
        this.FrogsPoint = new this.web3.eth.Contract(abis.FrogsPointAbi);

        this.setProvider(provider);
    }

    setProvider(provider: any) {
        const setProvider = (contract: any, address: string) => {
            contract.setProvider(provider);
            if (address) {
                contract.options.address = address;
            } else console.error('Contract address not found in network');
        };

        setProvider(this.TINY, this.config.TINY);
        setProvider(this.PAY, this.config.contractAddresses.TokenPay);
        setProvider(this.EggNFT, this.config.contractAddresses.EggNFT);
        setProvider(this.TinyNFT, this.config.contractAddresses.TinyNFT);
        setProvider(this.UpDown, this.config.contractAddresses.UpDown);
        setProvider(this.UpDownRef, this.config.contractAddresses.UpDownRef);
        setProvider(this.UpDownNFT, this.config.contractAddresses.UpDownNFT);
        setProvider(this.TailToken, this.config.contractAddresses.TailToken);
        setProvider(this.TailNFT, this.config.contractAddresses.TailNFT);
        setProvider(this.FrogsPoint, this.config.contractAddresses.FrogsPoint);
    }

    setDefaultAccount(account: string) {
        this.TINY.options.from = account;
        this.PAY.options.from = account;
    }
}
