const SvgIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        fill="none"
        viewBox="0 0 17 16"
    >
        <g fill="#fff" clipPath="url(#clip0_2773_3123)">
            <path d="M8.386 7.707c1.059 0 1.976-.38 2.725-1.129a3.73 3.73 0 0 0 1.129-2.725 3.73 3.73 0 0 0-1.13-2.724A3.73 3.73 0 0 0 8.387 0C7.327 0 6.41.38 5.661 1.129a3.73 3.73 0 0 0-1.129 2.725c0 1.058.38 1.975 1.13 2.724a3.73 3.73 0 0 0 2.724 1.13M15.129 12.303a10 10 0 0 0-.13-1.01 8 8 0 0 0-.248-1.017 5 5 0 0 0-.418-.948 3.6 3.6 0 0 0-.63-.821 2.8 2.8 0 0 0-.905-.57c-.351-.138-.74-.208-1.156-.208-.163 0-.321.067-.626.265q-.284.185-.653.421a3.7 3.7 0 0 1-.844.372c-.342.11-.69.167-1.032.167s-.69-.056-1.033-.167a3.7 3.7 0 0 1-.844-.372l-.653-.42c-.305-.2-.463-.266-.626-.266-.416 0-.805.07-1.155.209-.352.14-.657.33-.906.569-.237.227-.45.503-.63.82a5 5 0 0 0-.417.949q-.15.471-.249 1.016a10 10 0 0 0-.13 1.012c-.02.306-.032.624-.032.944 0 .836.266 1.512.79 2.01.517.493 1.2.742 2.033.742h7.704c.832 0 1.516-.25 2.033-.742.524-.498.79-1.174.79-2.01 0-.322-.012-.64-.033-.945"></path>
        </g>
        <defs>
            <clipPath id="clip0_2773_3123">
                <path fill="#fff" d="M.5 0h16v16H.5z"></path>
            </clipPath>
        </defs>
    </svg>
);

export default SvgIcon;
