const SvgIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="14"
        fill="none"
        viewBox="0 0 13 14"
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5 .5a1 1 0 1 0 0 2h4.586L.293 11.793a1 1 0 1 0 1.414 1.414L11 3.914v4.454a1 1 0 1 0 2 0V1.5a1 1 0 0 0-1-1z"
            clipRule="evenodd"
        ></path>
    </svg>
);

export default SvgIcon;
