import {
    Config,
    readContract,
    ReadContractParameters,
    writeContract,
    WriteContractParameters,
} from '@wagmi/core';
import { Contract } from 'web3-eth-contract';
import { Abi, ContractFunctionArgs, ContractFunctionName } from 'viem';
import { config } from 'utils/wallet/wagmi';

export const readContractWagmi = async <
    const abi extends Abi | readonly unknown[],
    functionName extends ContractFunctionName<abi, 'pure' | 'view'>,
    args extends ContractFunctionArgs<abi, 'pure' | 'view', functionName>
>(
    contract: Contract,
    parameters: Omit<
        ReadContractParameters<abi, functionName, args, any>,
        'abi' | 'address'
    >
) => {
    const params = {
        ...parameters,
        abi: contract.options.jsonInterface as any,
        address: contract.options.address as any,
    } as ReadContractParameters<abi, functionName, args, any>;

    const result = await readContract(config, params);

    return result;
};

export const writeContractWagmi = async <
    config extends Config,
    const abi extends Abi | readonly unknown[],
    functionName extends ContractFunctionName<abi, 'nonpayable' | 'payable'>,
    args extends ContractFunctionArgs<
        abi,
        'nonpayable' | 'payable',
        functionName
    >,
    chainId extends config['chains'][number]['id']
>(
    contract: Contract,
    parameters: Omit<
        WriteContractParameters<abi, functionName, args, config, chainId>,
        'abi' | 'address'
    >
) => {
    const params = {
        ...parameters,
        abi: contract.options.jsonInterface as any,
        address: contract.options.address as any,
    } as any;

    const result = await writeContract(config as any, params);

    return result;
};

export const urlNft = (id: number) => {
    const baseUrl = process.env.REACT_APP_URL_TAILS_NFT;
    return `${baseUrl}/${id}.webp`;
};
