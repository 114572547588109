import { IconGift, IconRoll, IconSendCro } from 'assets';

export const configurations = {
    tinyAddress: '0xb58B168d65e3dBd5aE6A62A64cfc6389D146a82c',
    usdcAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    PoloDividenAddress: '0x3f04F8dEc53eAd6EC63279B138453bb7CFb7d701',
};

export const tokennomics = [
    {
        name: "Dev Fund: <b>100,000,000 (10%)</b> <span style='color: #7a7b7d;font-size: 14px'>(vesting in 1 year)</span>",
        y: 10,
        color: '#94cf3f',
        borderColor: '#ffffff',
    },
    {
        name: "MKT & Advisor: <b>100,000,000 (10%)</b> <span style='color: #7a7b7d;font-size: 14px'>(vesting in 1 year)</span>",
        y: 10,
        color: '#8423e1',
        borderColor: '#ffffff',
    },
    {
        name: "Game Treasury: <b>200,000,000 (20%)</b> <span style='color: #7a7b7d;font-size: 14px'>(vesting in 1 year)</span>",
        y: 20,
        color: '#f2229e',
        borderColor: '#ffffff',
    },
    {
        name: 'IDO: <b>400,000,000 (40%)</b>',
        y: 40,
        color: '#31d7bf',
        borderColor: '#ffffff',
    },
    {
        name: 'Initial Liquidity: <b>200,000,000 (20%)</b>',
        y: 20,
        color: '#0460fe',
        borderColor: '#ffffff',
    },
];

export const options_tokennomincs = {
    chart: {
        type: 'pie',
        backgroundColor: 'transparent',
    },
    title: {
        text: '',
    },

    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: false,
            },
        },
        pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.y}%</b>',
                distance: -50,
                style: {
                    color: '#ffffff',
                    textOutline: '0px contrast',
                    strokeWidth: 0,
                    fontWeight: 500,
                    fontFamily: '"Poppins", sans-serif',
                    fontSize: 14,
                },
            },
        },
    },

    series: [
        {
            colorByPoint: true,
            data: tokennomics,
            borderColor: '#ffffff',
            borderWidth: '2',
        },
    ],
};

export const MENU_ITEMS = [
    {
        label: 'Roll',
        icon: <IconRoll />,
        url: '/',
    },
    {
        label: 'NFT Collections',
        icon: <IconGift />,
        url: 'https://www.frogsclub.xyz/send',
        disabled: true,
    },
    {
        label: 'Send CRO',
        icon: <IconSendCro />,
        url: 'https://www.frogsclub.xyz/send',
        disabled: true,
    },

    // {
    //     label: 'Telegram bot',
    //     icon: <IconBot />,
    //     url: '',
    // },
];
