const SvgIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        fill="none"
        viewBox="0 0 17 16"
    >
        <path
            fill="#fff"
            d="m16.33 6.657-3.506 3.587.828 5.066a.6.6 0 0 1-.224.577.54.54 0 0 1-.593.045L8.5 13.54l-4.335 2.392a.54.54 0 0 1-.592-.045.6.6 0 0 1-.224-.578l.828-5.066L.67 6.657a.61.61 0 0 1-.142-.605.57.57 0 0 1 .454-.403l4.846-.738L7.995.303c.19-.404.82-.404 1.01 0l2.167 4.608 4.846.738a.57.57 0 0 1 .454.403.61.61 0 0 1-.142.605"
        ></path>
    </svg>
);

export default SvgIcon;
