import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import axios from 'axios';

import { isEqual } from 'lodash';

import { Context as ResultsContext } from './ResultsProvider';

import useTiny from '../hooks/useTiny';
import usePrevious from 'hooks/updown/usePrevious';

import { LOSE, WIN } from 'constants/updown';
import { useAccount } from 'wagmi';

export interface HistoryContext {
    allHistory: any;
    setAllHistory: any;
    myHistory: any;
    setMyHistory: any;
    stats: any;
}

export const Context = createContext<HistoryContext>({
    allHistory: null,
    setAllHistory: null,
    myHistory: null,
    setMyHistory: null,
    stats: null,
});

const HistoryProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const tiny = useTiny();
    const { address: account } = useAccount();

    // state for history
    const [allHistory, setAllHistory] = useState<any[]>([]);
    const [myHistory, setMyHistory] = useState<any[]>([]);
    const [stats, setStats] = useState<any>({});

    const {
        results,
        setResults,
        firstLoad,
        setFirstLoad,
        setLoading,
        setWinState,
    } = useContext(ResultsContext);

    const prevAccount = usePrevious(account);

    useEffect(() => {
        setMyHistory([]);
    }, [account]);

    const fetchAllHistory = useCallback(async () => {
        if (!tiny) return;

        if (prevAccount && prevAccount !== account) {
            await setFirstLoad(true);

            return;
        }

        const queryAll = `{
      events(first: 100, orderBy: round, orderDirection: desc) {
        id
        player
        index
        number
        isOver
        amount
        isFinished
        luckyNumber
        winAmount
      },
      updowns(first: 100) {
        id
        totalBet
        totalWinning
        totalPlayer
      }
    }`;

        const responseAllHistory = await axios.post(
            'https://graph.frogsclub.xyz/query/subgraphs/name/frogs-updown-subgraph',
            { query: queryAll }
        );

        const stats = responseAllHistory?.data?.data?.updowns?.[0] || {};

        setStats(stats);

        const newHistory = responseAllHistory.data.data?.events;

        if (
            responseAllHistory &&
            responseAllHistory.data &&
            responseAllHistory.data.data &&
            !isEqual(newHistory, allHistory)
        ) {
            // only change state when data change
            setAllHistory([...newHistory]);
        }

        if (account) {
            const queryCurrentUser = `{
        events(
          first: 100,
          where: {
            player: "${account.toLocaleLowerCase()}"
          },
          orderBy: round,
          orderDirection: desc
        ) {
          id
          player
          index
          number
          isOver
          amount
          isFinished
          luckyNumber
          winAmount
        },
      }`;
            //   nftId

            const responseMyHistory = await axios.post(
                'https://graph.frogsclub.xyz/query/subgraphs/name/frogs-updown-subgraph',
                { query: queryCurrentUser }
            );

            const subGraphData = responseMyHistory.data.data.events?.length
                ? responseMyHistory.data.data?.events
                : [];

            const newHistory = [...subGraphData];

            if (
                responseMyHistory &&
                responseMyHistory.data &&
                responseMyHistory.data.data &&
                !isEqual(newHistory, myHistory)
            ) {
                // only change state when data change

                const lastHistory = newHistory[0];

                if (
                    lastHistory &&
                    lastHistory.isFinished &&
                    Number(results?.index) !== Number(newHistory[0].index)
                ) {
                    await setLoading(false);

                    await setResults(lastHistory);

                    const { isOver, number, luckyNumber } = lastHistory;

                    if (
                        (isOver && Number(luckyNumber) > Number(number)) ||
                        (!isOver && Number(luckyNumber) < Number(number))
                    ) {
                        if (!firstLoad) {
                            await setWinState(WIN);
                        }
                    } else {
                        if (!firstLoad) {
                            await setWinState(LOSE);
                        }
                    }

                    await setLoading(false);

                    await setFirstLoad(false);
                }

                setMyHistory([...newHistory]);
            }
        }
    }, [prevAccount, firstLoad, allHistory, myHistory, results, account, tiny]);

    // auto fetch every 3 second

    useEffect(() => {
        if (!tiny) return;

        fetchAllHistory();

        const timeOutFetchHistory = setInterval(() => {
            fetchAllHistory();
        }, 5000);

        return () => clearInterval(timeOutFetchHistory);
    }, [results, firstLoad, prevAccount, account, tiny]);

    return (
        <Context.Provider
            value={{
                allHistory,
                setAllHistory,
                myHistory,
                setMyHistory,
                stats,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default HistoryProvider;
