const SvgIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
    >
        <g fill="#fff" clipPath="url(#clip0_2773_3105)">
            <path d="m8.658 7.802 6.204-3.913a2.7 2.7 0 0 0-.93-.885L9.36.364a2.72 2.72 0 0 0-2.719 0l-4.573 2.64c-.33.19-.617.449-.84.758l6.417 4.04a.95.95 0 0 0 1.014 0M6.934 3.578A1.066 1.066 0 1 1 8 4.643a1.066 1.066 0 0 1-1.066-1.065M7.94 8.335a1.35 1.35 0 0 1-.511-.19l-6.415-4.04A2.7 2.7 0 0 0 .707 5.36v5.281c0 .972.519 1.87 1.36 2.355l4.573 2.64c.396.229.843.354 1.3.364V8.335M2.36 12.069c-.426-.314-.76-1.015-.76-1.571s.335-.784.762-.498c.44.294.806 1.014.806 1.6s-.368.794-.808.47m3.27-1.55c-.427-.315-.763-1.015-.763-1.571s.336-.784.762-.498c.44.294.806 1.014.806 1.602 0 .587-.366.792-.806.467M15.053 4.245l-6.179 3.9c-.16.1-.341.167-.53.193v7.641a2.7 2.7 0 0 0 1.016-.342l4.573-2.641a2.72 2.72 0 0 0 1.36-2.355V5.36c0-.385-.082-.765-.24-1.115m-4.952 10.179c-.426.285-.763.058-.763-.498s.337-1.256.763-1.571c.44-.327.806-.12.806.467 0 .588-.366 1.308-.806 1.602m0-3.874c-.426.285-.763.058-.763-.498s.337-1.256.763-1.571c.44-.326.805-.12.805.466s-.365 1.31-.805 1.603m3.576 1.56c-.426.285-.763.059-.763-.498s.337-1.256.763-1.571c.44-.325.806-.12.806.467 0 .588-.367 1.308-.806 1.602m0-3.874c-.426.286-.763.058-.763-.497s.337-1.256.763-1.572c.44-.326.806-.12.806.468 0 .587-.367 1.307-.806 1.601"></path>
        </g>
        <defs>
            <clipPath id="clip0_2773_3105">
                <path fill="#fff" d="M0 0h16v16H0z"></path>
            </clipPath>
        </defs>
    </svg>
);

export default SvgIcon;
