const SvgIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill="#fff"
            d="M14.616 9.263c6.507 0 6.026 0 6.127.003a1.35 1.35 0 0 0-.352-.613l-1.784-1.784a1.33 1.33 0 0 0-1.473-.279.715.715 0 0 1-.79-.148.715.715 0 0 1-.147-.788 1.33 1.33 0 0 0-.278-1.473l-1.784-1.784a1.36 1.36 0 0 0-1.918 0L9.985 4.629zM9.153 5.46 5.35 9.263h7.602zM21.155 15.558c.505-.196.845-.694.845-1.239v-2.523c0-.748-.609-1.356-1.356-1.356h-2.762V22h2.762C21.39 22 22 21.392 22 20.644V18.12c0-.545-.34-1.043-.844-1.239a.715.715 0 0 1-.454-.662c0-.291.182-.557.453-.662M2 11.796v2.523c0 .545.339 1.043.844 1.239a.715.715 0 0 1 .453.662.715.715 0 0 1-.453.662A1.335 1.335 0 0 0 2 18.121v2.523C2 21.392 2.608 22 3.356 22h13.35V10.44H3.356c-.748 0-1.356.608-1.356 1.356m4.117 1.38h8.824v1.177H6.117zm0 2.353h8.824v1.177H6.117zm0 2.353h8.824v1.177H6.117z"
        ></path>
    </svg>
);

export default SvgIcon;
