/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState, useCallback } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';
import Web3 from 'web3';
import axios from 'axios';

import { getBalance } from 'utils/erc20';
import { Tiny } from 'common/Tiny';
import { currentConfig } from 'common/configs';
import { useAccount } from 'wagmi';
import { useEthersSigner } from 'hooks/signers';

export interface TinyContext {
    tiny?: Tiny;
    block: number;
    stats: any;
    payBalance: string;
}

export const Context = createContext<TinyContext>({
    tiny: undefined,
    block: 0,
    stats: undefined,
    payBalance: '0',
});

const TinyProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const { address: account } = useAccount();
    const signer = useEthersSigner();

    const [tiny, setTiny] = useState<Tiny>();
    const [block, setBlock] = useState(0);
    const [stats, setStats] = useState<any>();
    const [payBalance, setPayBalance] = useState('0');

    // // @ts-ignore
    // window.eth = ethereum;

    // useEffect(() => {
    //     if (!ethereum) return;

    //     const web3 = new Web3(ethereum);
    //     const interval = setInterval(async () => {
    //         const latestBlockNumber = await web3.eth
    //             .getBlockNumber()
    //             .catch(() => 0);
    //         setBlock((pre) => Math.max(pre + 1, latestBlockNumber));
    //     }, 3000);

    //     return () => clearInterval(interval);
    // }, [ethereum]);

    useEffect(() => {
        if (signer) {
            const lib = new Tiny(signer, {
                defaultAccount: account,
                defaultConfirmations: 1,
                autoGasMultiplier: 1.5,
                accounts: [],
                ethereumNodeTimeout: 10000,
            });

            setTiny(lib);
        } else {
            if (!tiny) {
                const lib = new Tiny(null, {
                    defaultConfirmations: 1,
                    autoGasMultiplier: 1.5,
                    accounts: [],
                    ethereumNodeTimeout: 10000,
                });
                setTiny(lib);
            }
            // const injected = new InjectedConnector({});
            // injected.isAuthorized().then((isAuthorized) => {
            //     if (isAuthorized) {
            //         let isSignOut = localStorage.getItem('signOut');
            //         if (!isSignOut) {
            //             connect('injected');
            //         }
            //     }
            // });
        }
    }, [signer]);

    const fetchStats = useCallback(async (source: any) => {
        try {
            const res = await axios.get(
                'https://api.frogs.city/api/public/stats',
                {
                    cancelToken: source.token,
                }
            );

            if (res && res.data) {
                const stats = res.data.data;
                setStats(stats);
            }
        } catch (err) {
            if (axios.isCancel(err)) {
            } else {
                throw err;
            }
        }
    }, []);

    useEffect(() => {
        const source = axios.CancelToken.source();

        fetchStats(source);

        const refreshStats = setInterval(() => {
            fetchStats(source);
        }, 5000);

        return () => {
            clearInterval(refreshStats);
            source.cancel();
        };
    }, []);

    useEffect(() => {
        if (!signer || !account) return;

        // const fetchBalance = async () => {
        //     const balance = await getBalance(
        //         signer,
        //         currentConfig.contractAddresses.TokenPay,
        //         account
        //     );
        //     setPayBalance(balance);
        // };

        // fetchBalance();
    }, [block, signer, account]);

    return (
        <Context.Provider value={{ tiny, block, stats, payBalance }}>
            {children}
        </Context.Provider>
    );
};

export default TinyProvider;
