import { useEffect, useState, useCallback } from 'react';
import useTiny from './useTiny';
import useBlock from './useBlock';
import { useAccount } from 'wagmi';
import _ from 'lodash';
import { multicall } from '@wagmi/core';
import { readContractWagmi } from 'utils/wagmi/commom';
import { RARITY } from 'utils';
import { config } from 'utils/wallet/wagmi';

const useMyFrogs = () => {
    const [nfts, setNfts] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const tiny = useTiny();
    const block = useBlock();
    const { address: account } = useAccount();

    const fetchNFTs = useCallback(async () => {
        if (!tiny) return;
        if (!account) {
            setNfts([]);
            return;
        }
        setLoading(true);

        try {
            // Count NFTs
            const balanceOf = await readContractWagmi(tiny.contracts.TailNFT, {
                functionName: 'balanceOf',
                args: [account],
            }).then((res) => Number(res));

            // Get NFT IDs
            const requestIds = [...Array(balanceOf)].map((_, i) => {
                return readContractWagmi(tiny.contracts.TailNFT, {
                    functionName: 'tokenOfOwnerByIndex',
                    args: [account, i],
                }).then((res) => Number(res));
            });
            const tokenIds = await Promise.all(requestIds);

            // Get NFTs info and rarity by IDs
            const calls = _.flatten(
                tokenIds.map((tokenId) => {
                    return [
                        {
                            address: tiny.contracts.TailNFT.options
                                .address as Address,
                            abi: tiny.contracts.TailNFT.options
                                .jsonInterface as any,
                            functionName: 'getNFTInfo',
                            args: [tokenId],
                        },
                        {
                            address: tiny.contracts.TailNFT.options
                                .address as Address,
                            abi: tiny.contracts.TailNFT.options
                                .jsonInterface as any,
                            functionName: 'rarityOfToken',
                            args: [tokenId],
                        },
                    ];
                })
            );

            const data = await multicall(config, {
                contracts: calls,
            });

            const mergedInfoNfts = tokenIds.map((nftId, i) => {
                const nftInfo = _.mapValues(
                    (data[i * 2].result as any)[1],
                    (o) => Number(o)
                );
                const rarity: any = data[i * 2 + 1].result;
                const rarityInfo = RARITY[rarity];
                return {
                    ...nftInfo,
                    rarity,
                    nftId,
                    rarityInfo,
                };
            });

            setNfts(mergedInfoNfts);
        } catch (error) {
            console.error('Failed to fetch NFTs:', error);
            setNfts([]);
        } finally {
            setLoading(false);
        }
    }, [tiny, account]);

    useEffect(() => {
        fetchNFTs();
    }, [fetchNFTs, block]);

    const refreshNFTs = useCallback(() => {
        fetchNFTs();
    }, [fetchNFTs]);

    return {
        nfts,
        loading,
        refreshNFTs,
    };
};

export default useMyFrogs;
