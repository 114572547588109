import { connectorsForWallets, getDefaultConfig } from '@rainbow-me/rainbowkit';
import { http } from 'viem';
import { sonicTestnet } from './chains';
import { createConfig } from 'wagmi';
import {
    metaMaskWallet,
    walletConnectWallet,
    coinbaseWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { cronos } from 'viem/chains';

const connectors = connectorsForWallets(
    [
        {
            groupName: 'Recommended',
            wallets: [metaMaskWallet],
        },
        {
            groupName: 'Others',
            wallets: [walletConnectWallet, coinbaseWallet],
        },
    ],
    {
        appName: 'Frogs',
        projectId: 'ba623853072f8eb32ba3ab5ad521ae4e',
    }
);

export const config = createConfig({
    chains: [cronos],
    transports: {
        [cronos.id]: http(),
    },
    connectors,
});
