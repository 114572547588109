import styled from 'styled-components';
import { Spin } from 'antd';

const Loading = () => {
    return (
        <LoadingComponent>
            <Spin /> <span>Loading...</span>
        </LoadingComponent>
    );
};

const LoadingComponent = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    span {
        margin-left: 10px;
    }
`;

export default Loading;
