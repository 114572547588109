const SvgIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        fill="none"
        viewBox="0 0 25 24"
    >
        <path
            fill="currentColor"
            d="M11.75 2c-4.219.004-6.401.08-7.786 1.464C2.58 4.85 2.504 7.031 2.5 11.25h4.914a4 4 0 0 1-.757-1.528C6.12 7.57 8.07 5.62 10.222 6.157c.572.143 1.09.406 1.528.757zM2.5 12.75c.004 4.218.08 6.4 1.464 7.785C5.35 21.92 7.531 21.995 11.75 22v-7.877A4.75 4.75 0 0 1 7.5 16.75a.75.75 0 0 1 0-1.5 3.25 3.25 0 0 0 3.163-2.5zM13.25 22c4.218-.005 6.4-.08 7.785-1.465 1.385-1.384 1.46-3.567 1.465-7.785h-8.163a3.25 3.25 0 0 0 3.163 2.5.75.75 0 0 1 0 1.5 4.75 4.75 0 0 1-4.25-2.627zM22.5 11.25c-.005-4.219-.08-6.401-1.465-7.786C19.651 2.08 17.468 2.004 13.25 2v4.914a4 4 0 0 1 1.527-.757c2.153-.538 4.104 1.412 3.565 3.565a4 4 0 0 1-.756 1.528z"
        ></path>
        <path
            fill="currentColor"
            d="M9.858 7.613a2.5 2.5 0 0 1 1.892 2.422v1.215h-1.215a2.5 2.5 0 0 1-2.422-1.892 1.44 1.44 0 0 1 1.745-1.745M13.25 10.035v1.215h1.215c1.145 0 2.144-.78 2.422-1.892a1.44 1.44 0 0 0-1.746-1.745 2.5 2.5 0 0 0-1.891 2.422"
        ></path>
    </svg>
);

export default SvgIcon;
