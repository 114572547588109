/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    createContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import { uniqBy } from 'lodash';

import useMyNFT from 'hooks/useMyNFT';
import useTiny from 'hooks/useTiny';
import useGetMoreNFT from 'hooks/updown/useGetMoreNFT';

import { getIndexNFT } from '../utils/common';
import { readContract } from '@wagmi/core';
import { config } from 'utils/wallet/wagmi';
import { useAccount } from 'wagmi';
import { formatEther } from 'viem';
import useMyFrogs from 'hooks/useMyFrogs';

export interface ResultsContext {
    currentNFT: any;
    userNFT: string;
    setUserNFT: any;
    fetchInfo: any;
    bonusRewards: number;
    fetchBoostedBonus: (id: string) => void;
}

export const Context = createContext<ResultsContext>({
    currentNFT: null,
    userNFT: '',
    setUserNFT: null,
    fetchInfo: null,
    bonusRewards: 1,
    fetchBoostedBonus: () => {},
});

const UserNFTProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const tiny = useTiny();

    const [userNFT, setUserNFT] = useState<any>('');
    const [bonusRewards, setBonusRewards] = useState(1);

    const { address: account } = useAccount();

    const { nfts: myNFTs } = useMyFrogs();

    const fetchInfo = async () => {
        try {
            // const userStakedNft = await tiny?.contracts.UpDownNFT.methods
            //     .locked(account)
            //     .call();

            const userStakedNft = await readContract(config, {
                abi: tiny!.contracts.UpDownNFT.options.jsonInterface,
                address: tiny!.contracts.UpDownNFT.options.address as Address,
                functionName: 'locked',
                args: [account],
            });

            const formatted = Number(userStakedNft);
            setUserNFT(formatted);
        } catch (error: any) {
            console.log('error');
        }
    };

    useEffect(() => {
        if (!account || !tiny) {
            setUserNFT('');
        } else {
            fetchInfo();
        }
    }, [tiny, account]);

    const myNFTLock = useGetMoreNFT(userNFT);

    const currentNFT = useMemo(
        () =>
            uniqBy(
                [myNFTLock, ...myNFTs].filter((item) => item),
                'nftId'
            ).find((item) => item.nftId === userNFT),
        [myNFTs, userNFT, myNFTLock]
    );

    const fetchBoostedBonus = useCallback(
        async (id: string) => {
            const index = await readContract(config, {
                abi: tiny!.contracts.UpDown.options.jsonInterface,
                address: tiny!.contracts.UpDown.options.address as Address,
                functionName: 'getBoostedIndex',
                args: [Number(id)],
            });
            const bonus = await readContract(config, {
                abi: tiny!.contracts.UpDown.options.jsonInterface,
                address: tiny!.contracts.UpDown.options.address as Address,
                functionName: 'bonusReward',
                args: [Number(index)],
            });

            return Number(bonus) / 100;
        },
        [currentNFT, tiny, account, userNFT]
    );
    const fetchBonus = useCallback(async () => {
        // const bonus = await tiny?.contracts.UpDown.methods
        //     .bonusReward(getIndexNFT(currentNFT.productivity))
        //     .call();

        const bonus = await readContract(config, {
            abi: tiny!.contracts.UpDown.options.jsonInterface,
            address: tiny!.contracts.UpDown.options.address as Address,
            functionName: 'bonusReward',
            args: [getIndexNFT(currentNFT.productivity)],
        });

        setBonusRewards(1 + Number(bonus) / 100);
    }, [currentNFT, tiny, account, userNFT]);

    useEffect(() => {
        if (!account || !tiny) return;

        if (currentNFT && currentNFT.productivity) {
            fetchBonus();
        } else {
            setBonusRewards(1);
        }
    }, [tiny, account, fetchBonus, currentNFT, userNFT]);

    return (
        <Context.Provider
            value={{
                currentNFT,
                bonusRewards,
                userNFT,
                setUserNFT,
                fetchInfo,
                fetchBoostedBonus,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default UserNFTProvider;
