/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react';

export interface ResultsContext {
    loading: any;
    setLoading: any;
    results: any;
    setResults: any;
    firstLoad: boolean;
    setFirstLoad: any;
    winState: any;
    setWinState: any;
    disableBtnRoll: boolean;
    setDisableBtnRoll: any;
}

export const Context = createContext<ResultsContext>({
    loading: false,
    setLoading: null,
    results: null,
    setResults: null,
    firstLoad: true,
    setFirstLoad: null,
    winState: null,
    setWinState: null,
    disableBtnRoll: false,
    setDisableBtnRoll: null,
});

const ResultsProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    // state for first load page get last bet of current user
    const [firstLoad, setFirstLoad] = useState(true);

    // state for placeBet action
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [disableBtnRoll, setDisableBtnRoll] = useState(false);

    // state for win state
    const [winState, setWinState] = useState<any>(null);

    // stop notification win or lose after 15 seconds
    useEffect(() => {
        if (winState) {
            setTimeout(() => setWinState(null), 15000);
        }
    }, [winState, setWinState]);

    return (
        <Context.Provider
            value={{
                loading,
                setLoading,
                results,
                setResults,
                firstLoad,
                setFirstLoad,
                winState,
                setWinState,
                disableBtnRoll,
                setDisableBtnRoll,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export default ResultsProvider;
