import { AbiItem } from 'web3-utils';

export const abis = {
    ERC20Abi: require('./abi/erc20.json') as AbiItem[],
    TDOAbi: require('./abi/tdo.json') as AbiItem[],
    EggNFTAbi: require('./abi/egg_nft.json') as AbiItem[],
    TinyNFTAbi: require('./abi/tiny_nft.json') as AbiItem[],
    UpDownAbi: require('./abi/updown.json') as AbiItem[],
    UpDownRefAbi: require('./abi/updown_ref.json') as AbiItem[],
    UpDownNFTAbi: require('./abi/updown_nft.json') as AbiItem[],
    TailTokenAbi: require('./abi/frog_token.json') as AbiItem[],
    TailNFTAbi: require('./abi/frog_nft.json') as AbiItem[],
    FrogsPointAbi: require('./abi/frogs_point.json') as AbiItem[],
};
