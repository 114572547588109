import HeaderBack from './HeaderBack';

export const LayoutBackHeader: React.FC<{ children: React.ReactNode }> = ({
    children,
    ...rest
}: any) => {
    return (
        <div>
            <HeaderBack />
            {children}
        </div>
    );
};

export default LayoutBackHeader;
